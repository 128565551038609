<template>
	<div class="container">
		<div class="header">
			<a @click="goback"><img src="../assets/images/back.png" alt=""></a> {{ title }}</div>
		<div class="blian22"></div>
		<!-- 筛选 -->
		<div>
			<div class="search-tab">
				<!-- ❀(fhq) -->
				<!-- <dl>
					<dt>学段：</dt>
					<dd>
						<span :class="learnSection == item.tagId ? 'active' : ''" v-for="(item,index) in learnSectionArr" :key="index" @click="changeTab(1,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl>
				<dl>
					<dt>版本：</dt>
					<dd>
						<span :class="edition == item.tagId ? 'active' : ''" v-for="(item,index) in editionArr" :key="index" @click="changeTab(4,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl> -->
				<!-- <dl>
					<dt>学段：</dt>
					<dd>
						<span :class="learnSection == item.tagId ? 'active' : ''" v-for="(item,index) in learnSectionArr" :key="index" @click="changeTab(1,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl> -->
				<dl>
					<dt>年级：</dt>
					<dd style="white-space: pre-line;">
						<span :class="grade == item.tagId ? 'active' : ''" v-for="(item,index) in gradeArr" :key="index" @click="changeTab(2,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl>
				<!-- <dl v-if="grade">
					<dt>科目：</dt>
					<dd style="white-space: pre-line;">
						<span :class="subject == item.tagId ? 'active' : ''" v-for="(item,index) in subjectArr" :key="index" @click="changeTab(3,item.tagId)">{{item.tagName}}</span>
					</dd>
				</dl> -->
				<dl>
					<dt>版本：</dt>
					<dd style="white-space: pre-line;">
						<span :class="edition == item.tagId ? 'active' : ''" v-for="(item,index) in editionArr" :key="index" @click="changeTab(4,item.tagId)">{{item.tagName}}</span>
					</dd>
					<dt v-if="!ifdropDown && ifshowDown" @click="godropDown">
						<img src="../assets/images/down1.png" alt="" class="zip downImg">
					</dt>
					<dt v-else-if="ifdropDown && ifshowDown" @click="godropDown">
						<img src="../assets/images/up1.png" alt="" class="zip downImg">
					</dt>
				</dl>
				<dl>
					<dt>册别：</dt>
					<dd>
						<span :class="volumes == item.tagId ? 'active' : ''" v-for="(item,index) in volumesArr" :key="index" @click="changeTab(5,item.tagId,item.tagName)">{{item.tagName}}</span>
					</dd>
				</dl>
			</div>

			<van-list class="home-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getBooks">
				<ul>
					<li v-for="(item,index) in listArr" :key="index">
						<!--<img :src="item.bookPic" alt="" class="zip">
					<p class="ellipsis_one">{{item.name}}</p>-->
						<router-link :to="{path:'./demandcont?id=' + item.bookId}">
							<!-- ❀(fhq) -->
							<!-- <img :src="item.bookPic" alt="" class="zip"> -->
							<img v-if="item.bookPic != '' && item.bookPic != null " :src="item.bookPic" alt="" class="zip">
							<img v-else src="../assets/images/default-book.png" alt="" class="zip">
							<!-- <img src="../assets/images/favicon.png" style="width: 100px; height: 100px;" alt="" class="zip"> -->
							<p class="ellipsis_two">{{item.bookName}}</p>
						</router-link>
					</li>
				</ul>
			</van-list>

		</div>
	</div>
</template>
<script>
	import { tags, getBooks, getBooksNew, getVersionListByApiBooks, tagsNew} from "@/assets/js/api";
	import { List } from 'vant';
	export default {
		name: "english",
		data() {
			return {
				/* fhq */
				// learnSectionArr: [], //科目
				// editionArr: [], //版本
				// edition: '',
				// learnSection: 0,

				title: '',
				/* fhq */
				// learnSectionArr: [], //学段
				gradeArr: [], //年级
				editionArr: [], //版本
				editionAll: [], //版本
				editionArrTree: [], //版本
				volumesArr: [], //册别
				subjectArr: [], //课程

				edition: '',
				grade: '',
				// learnSection:0,
				volumes: '',
				subject: '',
				// 册别名称
				volumesName: '',

				// 列表
				listArr: [],
				type: '',
				//下拉上拉
				// 列表
				page: 1,
				limit: 9,
				/* 下拉的显示与隐藏 */
				ifshowDown:false,
				ifdropDown:false,

				loading: false,
				finished: false,
			};
		},
		methods: {
			goback() {
				sessionStorage.setItem("sign","1")
				this.$router.back()
			},
			changeTab: function(type, index, name) {
				/* fhq */

				// if(type == 1) {
				// 	this.learnSection = this.learnSection == index ? '' : index;
				// } else if(type == 4) {
				// 	this.edition = this.edition == index ? '' : index;
				// }
				// this.page = 1;
				// this.listArr = [];
				// this.getBooks()

				/* fhq */
				/* 
				if(type == 1) {
					this.learnSection = this.learnSection == index ? '' : index;
					this.getTags(2, this.learnSection);
					this.grade = '';
					this.subject = '';
				} else if (type == 2) {
					this.grade = this.grade == index ? '' : index;
					this.getTags(3, this.grade);
					this.subject = '';
				} else if (type == 3) {
					this.subject = this.subject == index ? '' : index;
				} else if (type == 4) {
					this.edition = this.edition == index ? '' : index;
				} else if (type == 5) {
					this.volumes = this.volumes == index ? '' : index;
					// 获取册别名称
					this.volumesName = this.volumesName == name ? '' : name;
				}
				*/
			   if (type == 2) {
			   	this.grade = this.grade == index ? '' : index;
			   	this.getTags(3, this.grade);
			   	this.subject = '';
			   } else if (type == 3) {
			   	this.subject = this.subject == index ? '' : index;
			   } else if (type == 4) {
					this.edition = this.edition == index ? '' : index;
				} else if (type == 5) {
			   	this.volumes = this.volumes == index ? '' : index;
			   	// 获取册别名称
			   	this.volumesName = this.volumesName == name ? '' : name;
			   }
				
				this.page = 1;
				this.listArr = [];
				/* 切换的时候 不只一页 继续请求 */
				this.finished = false;
				this.getBooks();
				// 查询版本
				this.getEditions()
			},
			// 下拉点击事件
			godropDown(){
				// 当点击下拉的时候 将三个变为全部
				if(!this.ifdropDown){
					this.ifdropDown = true
					this.editionArr = this.editionAll
				}else if(this.ifdropDown){
					// 当点击收起的时候 将全部变为三个
					this.ifdropDown = false
					this.editionArr = this.editionArrTree 
				}
			},
			//获取图书列表
			getBooks() {
				/* fhq(❀) */
				// let params = {
				// 	learnSection: this.learnSection,
				// 	bookType: this.type,
				// 	edition: this.edition,
				// 	page: this.page,
				// 	limit: this.limit
				// }
				// console.log();
				/* 查询 "全一册" 的数组 */
				var AllvolumeObj = this.findWhatYouWant("全一册")
				/* 全一册的id */
				var volumesOne = ""
				
				if(this.volumesName != '' && this.volumesName != "全一册"){
					// console.log(this.volumesName);
					if( AllvolumeObj != null && AllvolumeObj != ""){
						volumesOne = AllvolumeObj.tagId
						// console.log(volumesOne);
					}
				}
				// volumesOne
				/* fhq(❀) */
				let params = {
					learnSection: 0,
					volumes: this.volumes,
					volumesOne:volumesOne,
					grade: this.grade,
					subject: this.subject,
					edition: this.edition,
					bookType: this.type,
					page: this.page,
					limit: this.limit
				}
				// getBooks(params).then(res => {
				// 	this.loading = false;
				// 	if (res.code == 0) {
				// 		if (res.books.length > 0) {
				// 			if (this.page == 1) {
				// 				this.listArr = res.books;
				// 			} else {
				// 				this.listArr = this.listArr.concat(res.books);
				// 			}
				// 			if (res.books.length < 9) {
				// 				this.finished = true;
				// 			} else {
				// 				this.page++;
				// 			}
				// 		} else {
				// 			this.finished = true;
				// 		}
				// 	}
				// });
				
				getBooksNew(params).then(res => {
					this.loading = false;
					if (res.code == 0) {
						if (res.list.length > 0) {
							// this.page 
							if (res.currentPage == 1) {
								this.listArr = res.list;
							} else {
								this.listArr = this.listArr.concat(res.list);
							}
							if (res.list.length < 9) {
								this.finished = true;
							} else {
								// this.finished = false;
								this.page = res.currentPage
								this.page++;
							}
						} else {
							this.finished = true;
						}
					}
				});
			},
			// 获取版本
			getEditions() {
				var AllvolumeObj = this.findWhatYouWant("全一册")
				/* 全一册的id */
				var volumesOne = ""
				
				if(this.volumesName != '' && this.volumesName != "全一册"){
					// console.log(this.volumesName);
					if( AllvolumeObj != null && AllvolumeObj != ""){
						volumesOne = AllvolumeObj.tagId
						console.log(volumesOne);
					}
				}
				/* fhq(❀) */
				let params = {
					learnSection: 0,
					volumes: this.volumes,
					volumesOne:volumesOne,
					grade: this.grade,
					subject: this.subject,
					edition: this.edition,
					bookType: this.type
				}
				getVersionListByApiBooks(params).then(res => {
					this.loading = false;
					// console.log(res)
					if(res.tags.length>3){
						this.ifshowDown = true
						// 所有的版本
						let edition = res.tags
						// 截取前三个版本
						edition = edition.slice(0,3)
						// console.log(edition)
						// 存储所有的版本
						this.editionAll = res.tags;
						// 存储前三个版本
						this.editionArr = edition;
						this.editionArrTree = edition;
					}else{
						this.ifshowDown = false
						this.editionArr = res.tags;
					}
					// this.editionArr = res.tags;
				});
			},
			getTags(tagType) {
				// tags({
				// 	tagType: tagType
				// }).then(res => {
				// 	if (res.code == 0) {
				// 		/* fhq修改 */
				// 		// if(tagType == 1) {
				// 		// 	this.learnSectionArr = res.tags;
				// 		// } else if(tagType == 4) {
				// 		// 	this.editionArr = res.tags;
				// 		// }
						
				// 		// if (tagType == 2) {
				// 		// 	this.gradeArr = res.tags;
				// 		// } else if (tagType == 3) {
				// 		// 	this.subjectArr = res.tags;
				// 		// }else if (tagType == 4) {
				// 		// 	this.editionArr = res.tags;
				// 		// }else if (tagType == 5) {
				// 		// 	this.volumesArr = res.tags;
				// 		// }
						
				// 		if (tagType == 2) {
				// 			this.gradeArr = res.tags;
				// 		} else if (tagType == 5) {
				// 			this.volumesArr = res.tags;
				// 		}
				// 	}
				// });
				tagsNew({
					tagType: tagType,
					bookTypeId:this.$route.query.bookType
				}).then(res => {
					if (res.code == 0) {
						if (tagType == 2) {
							this.gradeArr = res.tags;
						} else if (tagType == 5) {
							this.volumesArr = res.tags;
						}
					}
				});
			},
			// 查找符合条件的对象
			findObj(volumesArr, value) {
				return volumesArr.find((item, index, array) => {
					return Object.is(item.tagName, value)
				});
			},
			findWhatYouWant(value) {
				return this.findObj(this.volumesArr, value);
			}
		},
		watch: {
			title() {
				// this.edition = '';
				// this.learnSection = 0;
				// this.getTags(1);
				this.getTags(2);
				// this.getTags(4);
			}
		},
		activated() {
			this.type = this.$route.query.bookType;
			this.title = this.$route.query.titleName;
			
			/* 返回的时候清空选中 */
			if(sessionStorage.getItem("sign")){
				this.edition = ''
				this.grade = ''
				this.volumes = ''
				/* 恢复页面 */
				this.page = 1
				this.finished = false;
				/* end */
				/* 重新查询图书  查询版本 */
				this.getBooks();
				this.getEditions();
				/* end */
				sessionStorage.removeItem("sign")
			}
		},
		mounted() {
			this.type = this.$route.query.bookType;
			this.title = this.$route.query.titleName;
			//this.getBooks();
			this.getTags(2);
			// this.getTags(4);
			this.getTags(5);
			this.getEditions();
		}
	};
</script>
